/* loginpage Custom */
.auth_content {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.auth_section {
    width: 40%;
    max-width: 550px;
    margin: auto;
}
.account_mainContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    max-width: 1140px;
    gap: 30px;
}
.auth_content_wrapper {
    width: 100%;
    margin: auto;
    padding: 80px 25px;
}

.auth_image_section {
    width: 60%;
    height: 100vh;
}

.order_mainContainer {
    /* display: flex; */
    flex-direction: column;
    padding: 35px;
    background-color: #F5F5F5;
    margin-top: 100px;
    min-height: calc(100vh - 100px);
    align-items: center;
}

.main_container {
    display: flex;
    width: 100%;
    /* gap: 20px;    */
}

.head_title {
    font-size: 20px;
    text-align: center;
    font-family: AxiformaRegular;
    color: var(--textDard);
   
}

.form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
   
}
.profileContainer{
    width: 30%;
}
.formContainer{
    width: 100%;
    max-width: 650px;
    
}
.form_container{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    background-color: #fff;
    padding: 54px 76px 80px ;
    justify-content: space-between;
}
.head {
    display: flex;
    justify-content: space-between;
    height: auto;
    width: 100%;
    align-items: center;
}

.wrapper {
    margin-top: 30px;
    padding: 40px 80px;
    background-color: #fff;
    border-radius: 10px;
}
.detail_wrapper{
    padding-top: 30px;
}

.detail_wrapper .inputs {
    border: 1px solid rgb(157 157 157 / 50%);
    border-radius: 5px;
    height: 44px;
    padding: 0 20px;
    background-color: var(--textLight);
    color: var(--textHeadingBlack);
    font-family: AxiformaRegular;
    font-size: 15px;
    width: 100%;
}
.label{
    color: var(--textHeadingBlack);
    font-family: AxiformaRegular;
    font-size: 15px;
}
.detail_row {
    display: flex;
    gap: 20px;
}
.detail_wrapper{
    padding-top: 30px;
}
.detail_row>* {
    width: 100%;
}

.button_wrapper{
    display: flex;
    justify-content: center;
}

.input_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.image_full {
    height: 100%;
    width: 60%;
    object-fit: cover;
    position: fixed;
}

.theme_btn {
    background-color: var(--primaryColor);
    font-family: AxiformaMedium;
    padding: 15px 0;
    border: none;
    border-radius: 5px;
    width: 100%;
    color: var(--textHeadingWhite);
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    position: relative
}

.theme_input {
    border: 1px solid rgb(157 157 157 / 50%);
    border-radius: 5px;
    height: 60px;
    /* padding: 14px 20px; */
    background-color: var(--textLight);
    color: var(--inputColor);
    font-family: AxiformaMedium;
    font-size: 18px;
}

.theme_input::placeholder {
    font-size: 16px;
    color: var(--inputColor);
    font-family: Axiformalight;
}

.input_wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.theme_input:focus {
    border-color: var(--primaryColor) !important;
}

.auth_title {
    font-size: 30px;
    font-family: AxiformaBold;
}

.aut_subtitle {
    font-size: 18px;
    font-family: AxiformaRegular;
    color: var(--textHeadingBlack);
    margin-bottom: 50px;
}

.input_row {
    display: flex;
    justify-content: space-between;
}

.input_row .input_wrapper {
    width: 48%;
}

.auth_link {
    display: flex;
    justify-content: center;
    color: var(--textHeadingBlack);
    font-size: 18px;
    font-family: AxiformaMedium;
}

.auth_link a {
    color: var(--primaryColor);
    font-size: 18px;
    font-family: AxiformaMedium;
}

.auth_forget {
    margin-bottom: 40px;
    display: flex;
}

.auth_forget a {
    font-family: AxiformaMedium;
    font-size: 18px;
    color: var(--textDard);

}

.invalid_feedback {
    color: var(--error);
    font-size: 14px;
    font-family: AxiformaRegular;
}

.logo_auth {
    margin-bottom: 100px;
    text-align: center;
}

.password_field {
    width: 100%;
    position: relative;
}

.pass_icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: var(--inputColor);
}

.theme_btn:disabled {
    background-color: #dedede;
    cursor: none;
    color: #d3d3d3;
}


.check_box_row {
    display: flex;
    justify-content: space-between;
}

.inside_checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 20px;
    min-width: 32%;
}

@media (max-width:1400px) and (min-width : 991px) {
    .theme_btn {
        height: 50px;
        font-size: 16px;
    }

    .auth_forget a,
    .auth_link,
    .auth_link a {
        font-size: 16px;
    }

    .auth_title {
        font-size: 28px;
    }

    .aut_subtitle {
        font-size: 16px;
    }

    .logo_auth {
        margin-bottom: 60px;
    }
}

/* laptop styling */
@media (max-width: 991px) {
    .auth_content_wrapper {
        width: 90%;
    }

    .auth_image_section {
        display: none;
    }

    .input_row {
        flex-direction: column;
    }

    .input_row .input_wrapper {
        width: 100%;
    }

    .theme_input,
    .theme_btn {
        height: 50px;
    }

    .auth_forget a,
    .auth_link,
    .auth_link a {
        font-size: 16px;
    }

    .title {
        font-size: 30px;
        margin-bottom: 20px;
    }

    .auth_title {
        font-size: 22px;
    }

    .aut_subtitle {
        font-size: 15px;
    }

    .auth_content_wrapper {
        padding: 0;
    }

    .auth_content {
        height: 100vh;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../_assets/images/signupBg.png');
    }

    .logo_auth {
        margin-bottom: 50px;
    }

    .auth_section {
        width: 90%;
        background-color: var(--textHeadingWhite);
        border-radius: 10px;
        padding: 50px 0;
    }
}