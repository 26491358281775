/* //sidebar */

.sidebar {
    width: 300px;
    max-width: 290px;
    height: 100vh;
    position: fixed;
    background: #fff;
    z-index: 1111;
    transition: all .2s ease-in-out;
}

.sidebar.close{
    width: 100px;
    transition: all .2s ease-in-out;
}
.sidebar.close .sidebar_logo{
    gap: 0px;
    margin-left:10px
}
.menu_bars{
    height: 20px;
    width: 20px;
}
.sidebar_logo {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    transition: all .2s ease-in-out;
}
.sidebar.close .sidebar_link{
    display: none;
}
.sidebar_menu {
    height: calc(100% - 100px);
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sidebar_icons {
    height: 20px;
    width: 20px;
}

.sidebar_link {
    font-size: 16px;
    font-family: AxiformaRegular;
    color: var(--textDard);
}

.menu_item_wrapper:hover span.sidebar_link,
a.menu_item_wrapper.active span.sidebar_link {
    color: var(--textLight);
}

.menu_item_wrapper:hover ._iconHover svg,
a.menu_item_wrapper.active ._iconHover svg{
    fill: var(--textLight);
}

.menu_links:not(:last-child) {
    margin-bottom: 5px;
}

.menu_item_wrapper {
    display: flex;
    height: 56px;
    gap: 15px;
    padding: 20px;
    align-items: center;
    border-radius: 10px;
    transition: var(--transition03s);
    text-decoration: none;
    cursor: pointer;
    border: none;
    background-color: var(--textLight);
}

.menu_item_wrapper:hover {
    background: var(--primaryColor);
    transition: var(--transition03s)
}

a.menu_item_wrapper.active {
    background-color: var(--primaryColor);

}
.close_icon{
    display: none ;
}

@media (max-width: 1024px){
    .sidebar {
        position: fixed;
        background-color: #fff;
        box-shadow: -5px 11px 12px 6px #13111143;
        z-index: 999;
    }
    .menu_bars{
        display: none;
    }
    .close_icon{
        display: block;
    }
    .hideOnMobile{
        /* display: none; */
        position: fixed;
        left: -200%;
        transition: all 1s ease-in;
        z-index: 1111;
    }
    .showOnMobile{
        /* display: block; */
        position: fixed;
        left: 0px;
        transition: all .5s;
        z-index: 1111;
    }
}

