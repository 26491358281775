/* Order */

.order_mainContainer {
    display: flex;
    flex-direction: column;
    padding: 35px;
    background-color: #F5F5F5;
    margin-top: 100px;
    min-height: calc(100vh - 100px);


}

.main_container {
    display: flex;
    width: 100%;
    /* gap: 20px;    */

}

.content_wrapper {
    width: 75%;
}
.add_search {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: flex-end;
    width: 100%;
}
.search_icons {
    color: var(--textLight);
    
}
.serach_box {
    height: 40px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 10px;
    cursor: pointer;
    background-color: var(--primaryColor);
}

.search {
    height: 58px;
    position: relative;
    background-color: var(--textLight);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: 280px;
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    border-bottom: none;
}
.title {
    font-size: 26px;
    text-align: center;
    font-family: AxiformaRegular;
    color: var(--textDard);
}

.head_title {
    font-size: 20px;
    text-align: center;
    font-family: AxiformaRegular;
    color: var(--textDard);
}

.head {
    display: flex;
    justify-content: space-between;
    height: auto;
    align-items: center;
}

.head_detail {
    display: flex;
    justify-content: space-between;
    height: auto;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #DEDEDE;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    width: 100%;
}

.fileName {
    position: absolute;
    font-size: 11px;
    font-family: AxiformaRegular;
}

.button_import {
    width: 100%;
    cursor: pointer;
    padding: 10px 20px;
    background: var(--textLight);
    border: 1px solid #b1b1b1;
    border-radius: 5px;
    font-size: 14px;
    font-family: AxiformaRegular;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    max-width: 170px;
}

.orderList {
    margin-top: 25px;
}

.data {
    /* overflow: scroll; */
    min-height: 500px;
}

.filters_wrapper {
    width: 25%;
    max-width: 370px;
    background-color: #fff;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    padding: 25px 0;
    margin-top: 65px;
    margin-right: 20px;
    height: max-content;
}

.filter_title {
    font-size: 20px;
    font-family: AxiformaRegular;
    color: var(--textDard);
    padding: 0 25px;
    margin-bottom: 20px;
}

.search_form {
    padding: 0 25px;
    margin: 50px 0 70px;
}

.input_field {
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid #9d9d9d;
    border-bottom: none;
}

.order_status {
    border: 1px solid var(--inputColor);
    color: var(--textDard);
    font-size: 13px;
    font-family: AxiformaRegular;
    width: 100%;
    border-radius: 5px;
    height: 42px;
    padding: 0 10px;
}

.input_icons {
    position: absolute;
    top: 14px;
    left: 10px;
}

.input_icons svg {
    height: 15px;
}

.filter_label {
    font-size: 16px;
    font-family: AxiformaLight;
    color: var(--textDard);
}

.filter_inputWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    gap: 5px;
}

.filter_input {
    width: 100%;
    /* max-width: 332px; */
    height: 44px;
    border: 1px solid #9d9d9d !important;
    padding: 4px 0 0px 20px;
    border-radius: 5px;
    font-size: 16px !important;
    font-family: AxiformaRegular !important;
}

.filter_input::placeholder {
    color: var(--inputColor);
}

.filter_input:focus {
    border-color: var(--primaryColor);
}

.filter_button {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background-color: var(--primaryColor);
    color: var(--textLight);
    border: none;
    font-family: AxiformaRegular;
    font-size: 18px;
    margin-top: 20px;
}
.button_wrapper{
    display: flex;
    justify-content: center;
}
.input_btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
}
.download_btn{
    width: 180px!important;
    min-width: 160px!important;
    height: 38px!important;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}
.remove_btn{
    font-family: AxiformaMedium;
    cursor: pointer;
    font-size: 12px;
    line-height: 12px;
    color: var(--primaryColor);
    border: none;
    text-decoration: underline;
    background-color: #fff;
}
 .add_btn, .download_btn{
    height: 30px;
    width: 100px;
    background: var(--primaryColor);    
    border: none;
    color: var(--textLight);
    border-radius: 4px;
    cursor: pointer;
    font-family: AxiformaRegular;
    font-size: 12px;
    line-height: 12px;
}
.add_btn{
    /* float: right; */
    margin-top: 20px;
    width: 150px;
    height: 40px;
}
/* Modal  */
.sections_edit {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main_container_edit {
    max-width: 1300px;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
}
.main_container_shopify{
    max-width: 1210px;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 50px 0 ;
}

.wrapper {
    margin-top: 30px;
    padding: 40px 80px;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
}

.modelBox {
    position: absolute;
    top: 50%;
    left: 50%;
    overflow-y: scroll;
    height: auto;
    max-height: 90vh;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 750px;
    border-radius: 15px;
    background-color: var(--textLight);
    padding: 4px;
}

.modelBox::-webkit-scrollbar {
    display: none;
}

.closeBtn {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 40px;
    cursor: pointer;
}

.col_btn {
    text-decoration: none;
    padding: 12px;
    border: none;
    border-radius: 5px;
    background-color: var(--primaryColor);
    color: var(--textLight);
    font-family: AxiformaRegular;
    font-size: 14px;
    width: 100%;
    max-width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
}


.details{
    margin-bottom: 20px;
    position: relative;
}


.input_wrapper_status_sucess{
    display: flex;
    flex-direction: column;
}
.input_wrapper_status_canceled{
    display: flex;
    flex-direction: column;
}



.detail_box {
    display: flex;
    width: 50%;
}

.detail_roww img {
    max-width: 40px;
}   


.detail_wrapper .inputs {
    border: 1px solid rgb(157 157 157 / 50%);
    border-radius: 5px;
    height: 44px;
    padding: 0 20px;
    background-color: var(--textLight);
    color: var(--textHeadingBlack);
    font-family: AxiformaRegular;
    font-size: 15px;
    width: 100%;
}

.detail_wrapper .no_inputs {
    border: none;
    /* border-radius: 5px; */
    height: 44px;
    margin-left: -19px;
    padding: 0 20px;
    background-color: var(--textLight);
    color: var(--textHeadingBlack);
    font-family: AxiformaRegular;
    font-size: 15px;
    width: 100%;
}
.label{
    color: var(--textHeadingBlack);
    font-family: AxiformaRegular;
    font-size: 15px;
}
.detail_row {
    display: flex;
    gap: 10px;
}
.detail_wrapper{    
    padding-top: 30px;
}
.detail_row>* {
    width: 100%;
}

.detail_box>* {
    width: 50%;
}

.detail_title {
    font-size: 15px;
    color: var(--textDard);
    font-family: AxiformaRegular;
}

.detail_value {
    font-size: 14px;
    color: var(--textDard);
    font-family: AxiformaLight;
}

.input_container {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.input_label {
    font-size: 17px;
    font-family: AxiformaMedium;
    color: var(--textDard);
    margin-right: 20px;
}

.detail_roww{
    display: flex;
    gap: 14px;
    align-items: center;
    margin-top: 20px;
    font-family: AxiformaRegular;
    font-size: 14px;
    color: #222;
    border: 1px solid #cccccc1f;
    padding: 20px 16px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px #ddddddba;
    min-width: 270px;
    max-width: 100%;
}

.detail_wrapper_status{
    display: flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: start;
}

.input_wrapper_status_sucess span {
    font-size: 12px;
    color: #666;
}
.input_wrapper_status_canceled span{
    font-size: 12px;
    color: #666;
}


.input_btn {
    height: 40px;
    font-size: 16px;
    line-height: 16px;
    background-color: var(--primaryColor);
    color: var(--textLight);
    font-family: AxiformaRegular;
    width: 100%;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
}

.input_btn:disabled {
    background-color: #dedede;
    cursor: none;
    color: #d3d3d3;
}

.input_order {
    border: 1px solid var(--inputColor);
    color: var(--textDard);
    font-size: 13px;
    font-family: AxiformaRegular;
    width: 100%;
    max-width: 150px;
    border-radius: 5px;
    height: 42px;
    padding: 0 10px;
}
.order_form{
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}
.order_form .input_btn{
    max-width: 250px;
}
.order_form> *{
    width: 100%;
}

.container_Notfound {
    height: 500px;
    padding: 40px;
}

.notFound {
    background: url("../../_assets/images/no-result.png") no-repeat;
    background-size: contain;
    background-position: center;
    height: 100%;
}

@media(max-width: 1400px) {

    .main_container {
        flex-direction: column;
    }

    .filters_wrapper {
        width: 100%;
        max-width: 100%;
        margin: 0;
        margin-bottom: 50px;
    }

    .content_wrapper {
        width: 100%;
    }

    .form_filter {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .form_filter>div {
        width: 31%;
        justify-content: flex-end;
    }

    .filter_inputWrapper {
        margin-bottom: 0;
    }

    .search_form {
        margin: 30px 0 10px 0;
    }
}

@media (max-width: 1024px) {
    .main_container {
        width: 90%;
        margin: auto;
    }

    .order_mainContainer {
        margin: 100px 0 0 0 !important;
    }

    .form_filter>div {
        width: 48%;
        align-items: flex-start;
    }

    .order_mainContainer {
        padding: 40px 0;
    }
}

/* Mobile */
@media(max-width: 768px) {
    .form_filter>div {
        width: 100%;
    }

    .filter_input {
        max-width: 100%;
        height: 36px;
    }

    .head {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        gap: 10px;
    }

    .actions {
        justify-content: flex-start;
    }

    .filter_label,
    .filter_input {
        font-size: 13px !important;
    }

    .filter_button {
        height: 42px;
        font-size: 15px;
        margin: 0;
    }

}