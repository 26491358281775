
/* //navbar */
.navBar {
    height: 100px;
    width: 100%;
    justify-content: flex-end;
    padding: 35px;
    
}
.bLogo{
    height: 55px;
    width: 55px;
    border-radius: 50%;
}
nav.navBar.flex.align_c {
    top: 0;
    right: 0;
    position: fixed;
    z-index: 111;
    background: url('../../_assets/images/nav_bg.png') no-repeat #fff;
    background-size: cover;
}
.display_name {
    font-size: 18px;
    font-family: AxiformaMedium;
    color: var(--textHeadingBlack);
}

.navBar_content {
    gap: 20px;
}
.nav_logo{
    display: none;
}
.avatar {
    width: 55px;
    height: 55px;
    background-color: var(--primaryColor);
    color: var(--textLight);
    font-size: 26px;
    font-family: AxiformaMedium;
    border-radius: 50%;
}

@media (max-width:1024px) {
    .nav_logo{
        display: flex;
        gap: 20px;
    }
    .navBar{
        justify-content: space-between;
    }
}

@media (max-width:768px) {
    .logo{
        display: none;
    }
    .avatar{
        width: 40px;
        height: 40px;
        font-size: 18px;
    }
}

