:root {
    --textHeadingWhite: #ffffff;
    --textHeadingBlack: #1f1f1f;
    --textDard: #000;
    --textLight: #fff;
    --primaryColor: #019BF6;
    --inputColor: #dcdcdc;
    --error: #FF0000;
    --transition03s: .3s all ease-in-out;
}

*,
html,
body {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

a {
    color: inherit;
}

ul li {
    list-style: none;
}

.flex {
    display: flex;
}

.align_c {
    align-items: center;
}

.justify_c {
    justify-content: center;
}

::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #e0e0e0;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

.spinner {
    background: url("./_assets/icons/loader.gif");
    background-size: cover;
    height: 30px;
    width: 30px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.theme_input {
    border: 1px solid rgb(157 157 157 / 50%);
    border-radius: 5px;
    height: 60px;
    padding: 0 20px;
    background-color: var(--textLight);
    color: var(--textHeadingBlack);
    font-family: AxiformaMedium;
    font-size: 16px;
    width: 100%;
}

.theme_input:disabled {
    background-color: #dedede;
    cursor: none;
    color: #969696;
}

.theme_input::placeholder {
    font-size: 16px;
    color: var(--inputColor);
    font-family: Axiformalight;
}

.theme_input:focus {
    border-color: var(--primaryColor);
}
.input_location{
    border: 1px solid rgb(157 157 157 / 50%);
    border-radius: 5px;
    background-color: var(--textLight);
    color: var(--textHeadingBlack);
    font-family: AxiformaMedium;
    font-size: 16px;
    width: 100%;
    height: 60px;
    padding: 0 20px;
}
.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("/src/_assets/icons/error.svg");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.theme_input.is-invalid .pass_icon {
    display: none;
}

@font-face {
    font-family: AxiformaBlack;
    src: url(./_assets/fonts/Axiforma-Black.ttf);
}

@font-face {
    font-family: AxiformaBold;
    src: url(./_assets/fonts/Axiforma-Bold.ttf);
}

@font-face {
    font-family: AxiformaExtraBold;
    src: url(./_assets/fonts/Axiforma-ExtraBold.ttf);
}

@font-face {
    font-family: AxiformaLight;
    src: url(./_assets/fonts/Axiforma-Light.ttf);
}

@font-face {
    font-family: AxiformaMedium;
    src: url(./_assets/fonts/Axiforma-Medium.ttf);
}

@font-face {
    font-family: AxiformaRegular;
    src: url(./_assets/fonts/Axiforma-Regular.ttf);
}

@font-face {
    font-family: AxiformaSemiBold;
    src: url(./_assets/fonts/Axiforma-SemiBold.ttf);
}

@font-face {
    font-family: AxiformaThin;
    src: url(./_assets/fonts/Axiforma-Thin.ttf);
}

/* //main container */
.main_content {
    width: 100%;
    height: auto;
}

/* override */
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none !important;
}

/* laptop */
@media (max-width: 991px) {
    .theme_input {
        height: 50px;
    }
}

@media (max-width:1400px) and (min-width : 991px){
    .theme_input{
        height: 50px;
        font-size: 14px;
    }
}
@media (max-width: 1024px) {
    .main_content {
        margin: 0 !important;
    }
}
.status{
    padding: 6px 10px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 5px;
    font-family: AxiformaRegular;

}
.Ontheway, .Active, .On, .Pickedup, .onTheWay, .Ontheway, .ontheway, .closeed, .closed{
    background-color: #C8FFC7;
    border: 1px solid #5EAE5C;
    color: #5EAE5C;
}
.Cancelled, .Inactive, .Delayed, .overdue{
    background-color: #FFC7C7;
    border: 1px solid #AE5C5C;
    color: #AE5C5C;
}
.Delivered, .Paid, .paid, .Requested, .requested, .Delivered,.delivered{
    background-color: #E6F4FF;
    border: 1px solid #4D8698;
    color: #4D8698;
}
.Pending, .pending, .Sorted, .Refunded, .refunded {
    background-color: #FFEBBF;
    border: 1px solid #E79A08;
    color: #E79A08;
}
/* .on_route {
    background-color: #FFEBBF;
    border: 1px solid #E79A08;
    color: #E79A08;
}
.scheduled{
    background-color: #C8FFC7;
    border: 1px solid #5EAE5C;
    color: #5EAE5C;
} */